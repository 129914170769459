//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "InformationDialog",
  props: {
    show: Boolean
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    onSubmit() {
      this.$emit("click");
    }
  }
};