import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { queryGeneticAnalysisOrderById, queryGeneticAnalystByAccountId, queryGeneticAnalystServicesByHashId } from "@debionetwork/polkadot-provider";
import { formatUSDTE } from "@/common/lib/price-format.js";
export default {
  name: "ServiceAnalysisCard",
  data: () => ({
    showDetail: false,
    serviceName: null,
    description: "",
    duration: null,
    durationType: null,
    price: null,
    profileImage: null,
    analystName: null,
    specialization: null,
    profileLink: null,
    geneticOrderDetail: null,
    orderId: null
  }),
  props: {
    service: Object
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.metamask.web3
    }),
    computeDescription() {
      const validateLength = this.description.length >= 100 ? "".concat(this.description.slice(0, 100), "...") : this.description;
      return this.description ? validateLength : "No Description";
    },
    computeAvatar() {
      return this.profileImage ? this.profileImage : require("@/assets/defaultAvatar.svg");
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.isCreated = true;
      this.orderId = this.$route.params.id;
      await this.getServiceDetail();
    }
    this.serviceName = this.service.serviceName;
    this.description = this.service.description;
    this.duration = this.service.duration;
    this.durationType = this.service.durationType;
    this.price = "".concat(this.formatBalance(this.service.priceDetail[0].totalPrice, formatUSDTE(this.service.priceDetail[0].currency)), " ").concat(formatUSDTE(this.service.priceDetail[0].currency));
    this.analystName = "".concat(this.service.analystsInfo.info.firstName, " ").concat(this.service.analystsInfo.info.lastName);
    this.specialization = this.service.analystsInfo.info.specialization;
    this.profileImage = this.service.analystsInfo.info.profileImage;
    this.profileLink = this.service.analystsInfo.info.profileLink;
  },
  methods: {
    onClick() {
      this.$emit("click", this.service);
    },
    formatBalance(balance, currency) {
      let unit;
      currency === "USDT" || currency === "USDT.e" ? unit = "mwei" : unit = "ether";
      const formatedBalance = this.web3.utils.fromWei(String(balance.replaceAll(",", "")), unit);
      return Number(formatedBalance).toLocaleString("en-US");
    },
    async getServiceDetail() {
      this.geneticOrderDetail = await queryGeneticAnalysisOrderById(this.api, this.orderId);
      const serviceDetail = await queryGeneticAnalystServicesByHashId(this.api, this.geneticOrderDetail.serviceId);
      let {
        id: serviceId,
        ownerId: analystId,
        info: {
          name: serviceName,
          pricesByCurrency: priceDetail,
          expectedDuration: {
            duration,
            durationType
          },
          description,
          testResultSample
        }
      } = serviceDetail;
      const analystsInfo = await queryGeneticAnalystByAccountId(this.api, analystId);
      const service = {
        serviceId,
        analystId,
        serviceName,
        priceDetail,
        duration,
        durationType,
        description,
        testResultSample,
        analystsInfo
      };
      this.serviceName = service.serviceName;
      this.description = service.description;
      this.duration = service.duration;
      this.durationType = service.durationType;
      this.price = "".concat(this.formatBalance(service.priceDetail[0].totalPrice, formatUSDTE(service.priceDetail[0].currency)), " ").concat(formatUSDTE(service.priceDetail[0].currency));
      this.analystName = "".concat(service.analystsInfo.info.firstName, " ").concat(service.analystsInfo.info.lastName);
      this.specialization = service.analystsInfo.info.specialization;
      this.profileImage = service.analystsInfo.info.profileImage;
      this.profileLink = service.analystsInfo.info.profileLink;
    }
  }
};